<template>
  <div />
</template>

<script>

export default {
  created() {
    this.logout()
  },
  methods: {
    logout() {
      localStorage.removeItem(this.$JWT.config.storageTokenKeyName)
      localStorage.removeItem(this.$JWT.config.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  },
}
</script>