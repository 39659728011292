<template>
  <editor
    v-model="editorContent"
    api-key="r7p1pkhdt4t8bgle9303o0680fcbt7zwbl1lvypshyk8k1mh"
    tinymce-script-src="https://vibration-space.fra1.digitaloceanspaces.com/tinymce/tinymce.min.js"
    initial-value=""
    :init="mceInit"
    @change="handlerFunction"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor,
  },
  props: {
    content: { type: String, default: '' },
    objectType: { type: String, default: '' },
    options: { type: Object, default: () => {} }
  },
  data() {
    return {
      editorContent: this.content, 
      mceInit: {
        language: "en",
        base_url: "https://vibration-space.fra1.digitaloceanspaces.com/tinymce",
        language_url: "https://vibration-space.fra1.digitaloceanspaces.com/tinymce/langs/sk.js",
        height: this.options && this.options.height ? this.options.height : 500,
        force_p_newlines: false,
        force_br_newlines: true,
        convert_newlines_to_brs: false,
        remove_linebreaks: true,
        inline: this.options && this.options.inline ? this.options.inline : false,
        menubar: false,
        entity_encoding: "raw",
        entities: "160,nbsp,38,amp,60,lt,62,gt",
        cleanup: true,
        paste_auto_cleanup_on_paste: true,
        paste_postprocess(pl, o) {
          // eslint-disable-next-line no-param-reassign
          o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/gi, " ");
        },
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "searchreplace",
          "visualblocks",
          "code",
          "preview",
          "anchor",
          "insertdatetime",
          "media",
          "code",
          "help",
          "wordcount",
          "table",
        ],
        toolbar: [
          "undo redo | copy paste | blocks | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist",
          "link image | table | code preview | hr | help ",
        ],
        images_upload_handler: this.fileUpload,
        images_file_types: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg",
        content_css: false,
        skin: "tinymce-5",
        content_style: `body{font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;line-height:1.4;margin:1rem}table{border-collapse:collapse}table:not([cellpadding]) td,table:not([cellpadding]) th{padding:.4rem}table[border]:not([border="0"]):not([style*=border-width]) td,table[border]:not([border="0"]):not([style*=border-width]) th{border-width:1px}table[border]:not([border="0"]):not([style*=border-style]) td,table[border]:not([border="0"]):not([style*=border-style]) th{border-style:solid}table[border]:not([border="0"]):not([style*=border-color]) td,table[border]:not([border="0"]):not([style*=border-color]) th{border-color:#ccc}figure{display:table;margin:1rem auto}figure figcaption{color:#999;display:block;margin-top:.25rem;text-align:center}hr{border-color:#ccc;border-style:solid;border-width:1px 0 0 0}code{background-color:#e8e8e8;border-radius:3px;padding:.1rem .2rem}.mce-content-body:not([dir=rtl]) blockquote{border-left:2px solid #ccc;margin-left:1.5rem;padding-left:1rem}.mce-content-body[dir=rtl] blockquote{border-right:2px solid #ccc;margin-right:1.5rem;padding-right:1rem}`,
      },
    }
  },
  methods: {
    fileUpload(blobInfo, success, failure) {
      const formdata = new FormData()
      formdata.append('file', blobInfo.blob())
      

      this.$Files.createResource({ body: formdata })
        .then(response => {
          if (response.status === 201) {
            success(`${response.data.url}`)
          } else {
            failure(this.$t('messages.fileFailure'))
          }
        })
        .catch(() => {
          failure(this.$t('messages.fileFailure'))
        })
    },
    handlerFunction() {
      this.$emit('editorContentChanged', this.editorContent)
    },
  },
}
</script>

<style lang="scss">
.tox-tinymce-aux {
  z-index: 50000 !important;
}
</style>
